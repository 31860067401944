import React from "react"
import * as queryString from "query-string"
import styled from "styled-components"
import Layout from "../layouts"

const StyledContainer = styled.div`
  label {
    font-weight: normal;
  }
`
export default function ContactUs({ location }) {
  const { title } = queryString.parse(location.search)
  const subject = title ? `Informations about ${title}` : ""
  return (
    <Layout title="Contact us" description="Let's talk">
      <StyledContainer className="container is-widescreen">
        <div className="columns">
          <div className="column is-8 is-12">
            <h1 className="title has-text-primary has-text-centered">
              Contact us
            </h1>
            <p className="subtitle has-text-centered">
              Let's talk
            </p>
            <form
              name="contact"
              className="contact-form__wrap"
              action="/thanks"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <p className="is-hidden">
                <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
              </p>

              <input type="hidden" name="form-name" value="contact"/>

              <div className="field">
                <label className="label" htmlFor="name">Name <strong>*</strong></label>
                <div className="control">
                  <input className="input" required name="name" type="text"/>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="email">Email <strong>*</strong></label>
                <div className="control">
                  <input className="input" required name="email" type="email"/>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="link">Linkedin / IMDB profile <strong>*</strong></label>
                <div className="control">
                  <input className="input" required name="link" type="url"/>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="subject">Subject</label>
                <div className="control">
                  <input className="input" name="subject" type="text" defaultValue={subject}/>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="message">Message</label>
                <div className="control">
                  <textarea className="textarea" name="message"/>
                </div>
              </div>

              <div className="field has-text-centered">
                <div className="control">
                  <button type="submit" className="button is-rounded is-large is-primary action-button">
                    <span className="icon is-medium">
                      <i className="far fa-paper-plane"/>
                    </span>
                    <span>Send</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </StyledContainer>
    </Layout>
  )
}